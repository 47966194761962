<template>
  <tr :class="[isOpen ? 'expanded-row' : '', 'patient-examination-row']">
    <td>
      <div
        v-if="$can('prioritise_cases')"
        class="d-flex flex-wrap align-items-center"
      >
        <bookmark-icon
          v-if="examinationCollection.main_examination.isBookmarked"
          class="consultations-list-icon"
        /><flag-icon
          v-if="examinationCollection.main_examination.isFlagged"
          class="consultations-list-icon"
        /><priority-icon
          v-if="examinationCollection.main_examination.isPrioritized"
          class="consultations-list-icon"
        />
      </div>
      <div
        v-if="$can('mark_as_test_patient')"
        class="d-flex flex-wrap align-items-center"
      >
        <test-icon
          v-if="examinationCollection.main_examination.isTestPatient"
          class="consultations-list-icon"
        />
      </div>
    </td>

    <!-- TODO: decide on under_score or camelCase. It would be cleaner and easier to use under_score on all value -->
    <td style="word-break: break-all">
      {{ examinationCollection.main_examination.caseNo }}
    </td>
    <td>
      {{ location }}
      <span v-if="locationLaterality">{{ locationLaterality }}</span>
    </td>
    <td v-if="isExpanded">
      {{ examinationCollection.main_examination.createdAt }}
    </td>
    <td>
      {{ hasLastConsultationColumnHeader ? mostRecentConsultationDate : " " }}
    </td>
    <td v-if="isExpanded">
      {{ examinationCollection.main_examination.createdUser }}
    </td>
    <td>
      <case-status
        :hide-label="true"
        :examination="examinationCollection.main_examination"
      />
    </td>
    <td class="text-right">
      <button
        type="button"
        :class="[isOpen ? 'btn-primary' : 'btn-secondary', 'btn']"
        @click="toggleExamination"
      >
        <span
          ><span class="hidden-xs">{{
            isOpen ? trans("center.showLess") : trans("center.showMore")
          }}</span>
          <i
            class="far fa-angle-down"
            :class="[isOpen ? 'fa-angle-up' : 'fa-angle-down', 'far']"
        /></span>
      </button>
    </td>
  </tr>
</template>

<script>
import CaseStatus from "../../../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";
import BookmarkIcon from "../../../Utils/ActionIcons/BookmarkIcon.vue";
import FlagIcon from "../../../Utils/ActionIcons/FlagIcon.vue";
import PriorityIcon from "../../../Utils/ActionIcons/PriorityIcon.vue";
import TestIcon from "../../../Utils/ActionIcons/TestIcon.vue";
import { lang } from "../../../../i18n";

export default {
  components: {
    CaseStatus,
    BookmarkIcon,
    FlagIcon,
    PriorityIcon,
    TestIcon,
  },
  props: {
    examinationCollection: {
      main_examination: Object,
    },
    isExpanded: Boolean,
    isOpen: Boolean,
    hasLastConsultationColumnHeader: Boolean,
  },
  data() {
    return {
      location: undefined,
    };
  },
  computed: {
    locationLaterality() {
      const { main_examination } = this.examinationCollection;

      if ("case_data" in main_examination) {
        const { case_data } = main_examination;
        if (case_data.ulcer_location_laterality) {
          return `(${lang.get(`examination.${case_data.ulcer_location_laterality}`)})`;
        }
      }
    },
    mostRecentConsultationDate() {
      return (
        this.examinationCollection.examinations?.[0]?.photographedAt ?? "-"
      );
    },
  },
  mounted: function () {
    this.mapLocation();
  },
  methods: {
    toggleExamination: function () {
      this.$emit("toggle-open");
      if (this.isOpen && this.examinationCollection?.main_examination?.id) {
        this.deleteDocumentationNotifications();
      }
    },
    deleteDocumentationNotifications: function () {
      axios
        .delete(
          `/api/internal/tumour-wound/notification/${this.examinationCollection.main_examination.id}`,
        )
        .catch((error) => {
          console.log(
            "error updating patient documentation notification: ",
            error,
          );
        });
    },
    mapLocation: function () {
      const { main_examination } = this.examinationCollection;
      if ("case_data" in main_examination) {
        const { case_data } = main_examination;

        let locationField;
        if ("lesion_location" in case_data)
          locationField = case_data.lesion_location;
        else if ("ulcer_location" in case_data)
          locationField = case_data.ulcer_location;
        else return;

        let result = "";
        if (
          typeof locationField === "string" ||
          locationField instanceof String
        ) {
          result = this.trans(`examination.${locationField}`);
        } else if (locationField) {
          Object.keys(locationField).forEach((location) => {
            const translated = this.trans(`examination.${location}`);
            if (locationField[location])
              result += result === "" ? translated : `/${translated}`;
          });
        }

        this.location = result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expanded-row {
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;

  td {
    border-bottom: 2px solid #dee2e6 !important;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.patient-examination-row {
  td:first-of-type {
    border-left: 2px solid #f5f5f5;
  }

  td:last-of-type {
    border-right: 2px solid #f5f5f5;
  }

  td {
    border-top: 2px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    padding: 0.5rem;
  }

  .consultations-list-icon {
    padding: 0 2px;
  }
}

.far.fa-angle-down,
.far.fa-angle-up {
  font-size: large;
  vertical-align: middle;
}

.btn {
  min-width: 120px;
}

@media (max-width: 700px) {
  .btn {
    min-width: auto;
  }
}
</style>
