<template>
  <div class="tab-content">
    <div v-if="!isUploadingAiResults" class="text-right space-x-2">
      <Button
        type="button"
        variant="secondary"
        @click="toggleUploadingAiResults"
      >
        {{ lang.get("aiFeatures.researchCaseOptionAddAiResults") }}
      </Button>
      <Button
        type="button"
        :disabled="props.sendingBulkInvites"
        variant="secondary"
        @click="$emit('action:sendInvites', studyId)"
      >
        {{ lang.get("action.study.sendInvites.label") }}
      </Button>
    </div>

    <AddAiResultsPanel
      v-if="isUploadingAiResults"
      :loading-ai-results="loadingAiResults"
      class="mt-3"
      @on-cancel="onCancel"
      @upload-ai-results="uploadAiResults"
    />
    <StudyResultsImportStatisticsAlert
      v-if="studyResultsImportStatistics"
      :data-statistics="studyResultsImportStatistics"
      class="tw-mt-2"
      @close-alert="studyResultsImportStatistics = undefined"
    />
    <AsyncState
      :data-state="studyExaminationsState"
      class="research-cases-table-container"
    >
      <div v-if="!_size(studyExaminations)" class="overlay">
        <div class="overlay-content">
          {{ lang.get("aiFeatures.noStudyCasesInfoText") }}
        </div>
      </div>
      <div class="mt-3 research-cases-table">
        <div v-if="loading" class="overlay">
          <ClipLoader />
        </div>
        <div>
          <label>{{ lang.get("aiFeatures.workflowStatsInfoSum") }}: </label>
          {{ displayStatusCountsFor(studyExaminations) }}
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-actions-bar">
            <thead class="sortable-table-header">
              <tr>
                <th class="text-left">
                  {{ lang.get("examination.case_no") }}
                </th>
                <th>{{ lang.get("examination.caseStatusLabel") }}</th>
                <th>
                  {{ lang.get("aiFeatures.tableHeaderNumberOfParticipants") }}
                </th>
                <th v-for="type in studyWorkflowTypes" :key="type">
                  {{ lang.get(`model.studyWorkflowType.${type}.display`) }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="studyExamination in studyExaminations"
                :key="studyExamination.id"
              >
                <td class="text-left">
                  {{ studyExamination.examination.case_no }}
                </td>
                <td>
                  <StudyExaminationStatusBadge
                    :data-value="studyExamination.status"
                  />
                </td>
                <td>{{ studyExamination.status_counts.invited }}</td>
                <td
                  v-for="type in studyWorkflowTypes"
                  :key="type"
                  :title="lang.get('aiFeatures.workflowStatsInfo')"
                >
                  {{ displayStatusCountsForWorkflow(type)([studyExamination]) }}
                </td>
                <td>
                  <DropDownMenu
                    btn-style="btn-secondary"
                    :title="lang.get('general.edit')"
                    :items="getDropdownOptions(studyExamination)"
                    :loading="loading"
                    @on-item-click="onSelection($event, studyExamination.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </AsyncState>
  </div>
</template>
<script setup lang="ts">
import { reactify, useAsyncState } from "@vueuse/core";
import { store } from "../../../store.js";
import { useRoute } from "vue-router";
import AsyncState from "../../../design-system/AsyncState.vue";
import { userStudyExaminationsGet } from "../../../store/core/users";
import { computed, shallowRef, watch, watchEffect } from "vue";
import _size from "lodash/fp/size";
import StudyExaminationStatusBadge from "../../StudyExaminationStatusBadge.vue";
import { getStudyWorkflowTypes } from "../../../../../../app/Dermicus/Studies/StudyExaminationSummary";
import {
  displayStatusCountsFor,
  displayStatusCountsForWorkflow,
} from "../../../../../../app/Dermicus/Studies/StudyExaminationAttributeStatus";
import DropDownMenu from "../../Examinations/Examination/ExaminationComponents/components/DropDownMenu.vue";
import ClipLoader from "../../Utils/ClipLoader.vue";
import AddAiResultsPanel from "../Utils/AddAiResultsPanel.vue";
import Button from "../../../components/Layout/Button.vue";
import notifications from "../../Utils/notifications";
import {
  canInviteForStatus,
  canRemoveForStatus,
} from "../../../../../../app/Dermicus/Studies/StudyExaminationStatus";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";
import { userErrorMessage } from "../../../mixins/UserErrorMessage.vue";
import { lang } from "../../../i18n";
import { StudyResultsImportStatistics } from "../../../../../../app/Imports/StudyResultsImportStatistics";
import StudyResultsImportStatisticsAlert from "../../StudyResultsImportStatisticsAlert.vue";

const route = useRoute();
const props = defineProps<{
  sendingBulkInvites: boolean;
}>();

const studyId = shallowRef(null);
watch(
  () => parseInt(route.params["studyId"], 10),
  (value) => (studyId.value = value),
  { immediate: true },
);

let canInvitePerCase = false;
practitionerHttpService
  .get(`/api/internal/studies/${studyId.value}/can-invite-per-case`)
  .then((response) => {
    canInvitePerCase = response.data.canInvitePerCase;
  });

const studyExaminationsState = useAsyncState(
  (studyId) => userStudyExaminationsGet(store.state.user.id, studyId),
  undefined,
  { immediate: false },
);
watchEffect(() => studyExaminationsState.execute(0, studyId.value));

const studyExaminations = computed(() => studyExaminationsState.state.value);
const studyWorkflowTypes = reactify(getStudyWorkflowTypes)(studyExaminations);

const loading = shallowRef(false);
const uploadAiImg = shallowRef(false);
const loadingAiResults = shallowRef(false);
const actionExaminationId = shallowRef(null);
const isUploadingAiResults = shallowRef(false);
const studyResultsImportStatistics = shallowRef<StudyResultsImportStatistics>();

function onSelection(option, studyiId) {
  if (option.action === "sendInvitations") {
    sendInvitations(studyiId);
  } else if (option.action === "removeCase") {
    removeCase(studyiId);
  }
}

function sendInvitations(studyiId) {
  loading.value = true;

  practitionerHttpService
    .get(
      `/api/internal/study-admin/participants/invite/${studyId.value}/${studyiId}`,
    )
    .then(() => {
      notifications.saveSuccess();
      studyExaminationsState.execute(0, studyId.value);
    })
    .catch(userErrorMessage)
    .finally(() => (loading.value = false));
}

function uploadAiResults(file) {
  console.log("upload AI results with file: ", file);
  loadingAiResults.value = true;

  practitionerHttpService
    .post(`/api/internal/studies/${studyId.value}/import-results`, file)
    .then((response) => {
      isUploadingAiResults.value = false;
      studyExaminationsState.execute(0, studyId.value);
      studyResultsImportStatistics.value = response.data;
    })
    .catch(userErrorMessage)
    .finally(() => {
      loadingAiResults.value = false;
    });
}

function removeCase(studyiId) {
  // @ts-ignore-next-line
  this.$vueAlert
    .confirm(
      lang.get("aiFeatures.removeCaseFromStudyConfirmationMessage"),
      undefined,
      undefined,
      {
        confirmButtonText: lang.get("general.modalConfirmButton"),
        cancelButtonText: lang.get("general.modalCancelButton"),
      },
    )
    .then(() => {
      loading.value = true;
      practitionerHttpService
        .delete(
          `/api/internal/study-admin/examinations/connect/${studyId.value}/${studyiId}`,
        )
        .then(() => {
          studyExaminationsState.execute(0, studyId.value);
          notifications.saveSuccessCustomText(
            "aiFeatures.researchCaseSuccessfullyRemoved",
          );
        })
        .catch(userErrorMessage)
        .finally(() => (loading.value = false));
    })
    .catch(() => {
      console.log("on cancel");
    });
}

function toggleUploadingAiResults() {
  isUploadingAiResults.value = !isUploadingAiResults.value;
}

function onCancel() {
  loading.value = false;
  uploadAiImg.value = false;
  actionExaminationId.value = null;
  isUploadingAiResults.value = false;
}

function getDropdownOptions(studyExamination) {
  let options = [];

  // only if thre are AI results, admin should send invites to the study
  if (canInviteForStatus(studyExamination.status) && canInvitePerCase) {
    options.push({
      title: lang.get("aiFeatures.researchCaseOptionSendInvitations"),
      action: "sendInvitations",
    });
  }

  // if there are nobody invited to study yet, push option delete
  if (canRemoveForStatus(studyExamination.status)) {
    options.push({
      title: lang.get("aiFeatures.researchCaseOptionRemoveFromStudy"),
      action: "removeCase",
    });
  }

  return options;
}
</script>
<style lang="scss" scoped>
.research-cases-table-container {
  position: relative;

  .research-cases-table {
    min-height: 300px;
  }
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}

tr th,
tr td {
  text-align: center;
}
</style>
