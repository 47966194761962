<template>
  <div>
    <strong>{{ title }}</strong>
    <br />

    <p class="text-muted">
      <router-link :to="fullUrl">
        {{ data ? data : "-" }}
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: [String, Number],
  },
  computed: {
    fullUrl() {
      return `${this.$route.fullPath}/images`;
    },
  },
};
</script>
