<template>
  <div class="panel panel-default panel-fill col-md-12 px-0 m-0">
    <div class="panel-heading panel-heading-underline py-20 px-15 p-3">
      <!-- The should be generic -->
      <h3 class="panel-title">AI Results</h3>
    </div>
    <div class="panel-body">
      <div class="results-container">
        <div
          v-for="(value, index) in examinationData.datasets[0].data"
          :key="index"
          class="result-item"
        >
          <strong>{{ examinationData.labels[index] }}: </strong>
          <span>{{ Math.round(value) }}%</span>
          <div class="progress my-2">
            <div
              class="progress-bar-blue"
              role="progressbar"
              :style="`width: ${Math.round(value)}%;`"
              :aria-valuenow="Math.round(value)"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      examinationData: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Examination Data",
            backgroundColor: "#4d7dbb",
          },
        ],
      },
    };
  },
  mounted() {
    this.getAiData();
  },
  methods: {
    getAiData: function () {
      const { study_results } = this.examination;
      if (study_results.length === 0) return;
      const studyResult = study_results.find((res) => {
        return res && "type" in res && res.type === "external_result";
      });
      if (studyResult) {
        const result = studyResult.results;
        let aiData = Object.keys(result).map((key) => ({
          label: result[key].label,
          value: +(result[key].value * 100).toFixed(2),
        }));

        this.examinationData.labels = aiData.map((item) => item.label);
        this.examinationData.datasets[0].data = aiData.map(
          (item) => item.value,
        );
      }
    },
  },
};
</script>

<style scoped>
.panel {
  margin: 20px;
}
.panel-heading {
  background-color: #f5f5f5;
  padding: 10px;
}
.panel-body {
  padding: 20px;
}
.results-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.result-item {
  flex: 1;
  margin: 0 10px 16px;
}
.progress-bar-blue {
  background-color: #4d7dbb;
  font-size: 11px;
}
</style>
