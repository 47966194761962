<template>
  <field-panel :title="title">
    <div class="panel-body">
      <div v-if="loading" class="overlay">
        <ClipLoader />
      </div>
      <div v-if="formFields.length > 0" class="tw-space-y-6">
        <field
          v-for="field in formFields"
          :key="field.id"
          :examination-id="examination.id"
          :title="field.title"
          :data="field.data"
          :type="field.type"
          :disabled="field.disabled"
          :required="field.required"
          :extra-options="{
            ...field.extraOptions,
            requiredFormFieldsValid: requiredFormFieldsValid,
          }"
          @is-valid="handleIsValid(field.extraOptions.key, $event)"
          @field-unmount="handleFieldUnmount(field)"
        />
      </div>

      <div class="text-left">
        <span
          ><i class="far fa-asterisk" />
          {{ trans("center.requiredField") }}</span
        >
      </div>
    </div>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import { STORE_CONSTANTS } from "../../../../helpers/definitions";
import notifications from "../../../Utils/notifications";
import ClipLoader from "../../../Utils/ClipLoader.vue";
import _noop from "lodash/fp/noop";
import {
  getFieldConditionsByOperator,
  getFieldConditionsByType,
  isDisabled,
  isRequired,
  isVisible,
} from "../../../../lib/field-conditions";

export default {
  components: {
    FieldPanel,
    ClipLoader,
  },
  /**
   * Temporary solution to be able to push out the actual API request and enable
   * writing unit tests and stories, while not breaking the current generic
   * product implementation in FE.
   * @todo Take this in mind when writing generic products v2.
   */
  inject: {
    saveStudyData: { from: "SAVE_STUDY_DATA_ACTION", default: _noop },
  },
  props: {
    fields: Array,
    examination: Object,
    product: Object,
    viewOption: String,
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      title: "examination.diagnosiskHeadline",
      loading: false,
      valuesValid: {},
      disableFields: false,
      values: {},
    };
  },

  computed: {
    requiredFormFieldsValid() {
      return Object.values(this.valuesValid).every(Boolean);
    },
    formFields() {
      const { fields, examination } = this;
      const editableFields = [];

      if (fields.length) {
        fields.forEach((field) => {
          const filteredConditions = getFieldConditionsByOperator(
            field.field_conditions,
            "equals",
          );
          const visibleConditions = getFieldConditionsByType(
            filteredConditions,
            "visible",
          );
          const disabledConditions = getFieldConditionsByType(
            filteredConditions,
            "disabled",
          );
          const requiredConditions = getFieldConditionsByType(
            filteredConditions,
            "required",
          );

          const values = {
            ...examination,
            ...this.values,
          };

          const fieldConditions = {
            visible: isVisible(visibleConditions, values),
            disabled: isDisabled(disabledConditions, values),
            required: isRequired(requiredConditions, values),
          };

          if (field.visible && fieldConditions.visible) {
            const valueOptions = JSON.parse(
              JSON.stringify(JSON.parse(field.value_options)),
            );
            let extraOptions = undefined;
            if (field.extra_options) {
              extraOptions = JSON.parse(
                JSON.stringify(JSON.parse(field.extra_options)),
              );
            }
            editableFields.push({
              id: field.id,
              title: field.title?.startsWith("data.")
                ? field.title
                : field.name,
              data: valueOptions
                ? valueOptions.map((option) => ({
                    label: option.title?.startsWith("data.")
                      ? this.trans(option.title)
                      : this.trans(`examination.${option.title}`),
                    value: option.data,
                  }))
                : [],
              type: getFieldType(field),
              disabled: field.disabled || fieldConditions.disabled,
              required: field.required || fieldConditions.required,
              extraOptions: {
                key: field.name,
                valueOptions: valueOptions,
                examination: examination,
                onValueChange: this.onValueChange,
                onClick: this.onSubmit,
                fieldOptions: extraOptions,
              },
            });
          }
        });
      }

      return editableFields;
    },
  },

  mounted: function () {
    this.disableFields = this.examination.study_workflow_completed;
  },

  methods: {
    handleFieldUnmount: function (field) {
      delete this.values[field.extraOptions.key];
      delete this.valuesValid[field.extraOptions.key];
    },

    handleIsValid: function (key, isValid) {
      this.valuesValid[key] = isValid;
    },

    onValueChange: function (input) {
      this.values = {
        ...this.values,
        [input.key]: input.value,
      };
    },

    onSubmit: function () {
      this.loading = true;
      return this.saveStudyData({
        product: this.product,
        examination: this.examination,
        results: this.values,
      })
        .then(async () => {
          await this.$store?.dispatch(STORE_CONSTANTS.LOAD_STUDY_WORKFLOW, {
            userId: this.$store.state.user.id,
            studyExaminationId: this.$route.params.id,
          });

          notifications.saveSuccess();

          if (this.examination.study_workflow_completed) {
            this.$router.push({
              name: "research",
              query: { studyId: this.product.meta.study_id },
            });
          }
        })
        .catch((error) => {
          console.log("save results error: ", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
