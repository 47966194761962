<template>
  <div class="table-responsive">
    <button
      v-if="isSmallScreen"
      class="btn btn-grey btn-block"
      @click="toggleExpandTable"
    >
      <span v-if="!isExpanded"
        ><i class="fal fa-angle-double-left" />
        {{ trans("patient.expandTable") }}
        <i class="fal fa-angle-double-right"
      /></span>
      <span v-else
        ><i class="fal fa-angle-double-right" />
        {{ trans("patient.shrinkTable") }}
        <i class="fal fa-angle-double-left"
      /></span>
    </button>

    <table class="table table-hover">
      <thead class="sortable-table-header">
        <tr class="patient-list-header">
          <th v-for="(column, index) in columns" :key="index">
            {{ column }}
          </th>
        </tr>
      </thead>

      <tbody class="tab-pane">
        <PatientExaminationListItem
          v-for="(collection, index) in examinationCollections"
          :key="'patient-header' + index"
          :collection="collection"
          :product-definition="productDefinition"
          :is-expanded="isExpanded"
          :nr-of-columns="columns.length"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import PatientExaminationContainer from "./PatientExaminationContainer.vue";
import PatientExaminationHeader from "./PatientExaminationHeader.vue";
import PatientExaminationListItem from "./PatientExaminationListItem.vue";

export default {
  components: {
    PatientExaminationContainer,
    PatientExaminationHeader,
    PatientExaminationListItem,
  },
  props: {
    examinationCollections: Array,
    productDefinition: Object,
  },
  data: function () {
    return {
      columns: null,
      mobileScreenSize: 415,
      isSmallScreen: false,
      isExpanded: true,
      isOpen: false,
    };
  },
  created: function () {
    this.screenSize();
    this.columnNumber();
  },

  methods: {
    toggleExpandTable: function () {
      this.isExpanded = !this.isExpanded;
      this.columnNumber();
    },
    columnNumber: function () {
      if (this.isSmallScreen && !this.isExpanded) {
        this.columns = this.productDefinition.patient_overview.smallScreen;
      } else {
        this.columns = this.productDefinition.patient_overview.bigScreen;
      }
    },
    screenSize: function () {
      const width = screen.width;

      if (width < this.mobileScreenSize) {
        this.isSmallScreen = true;
        this.isExpanded = false;
      } else {
        this.isSmallScreen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-list-header {
  height: 100px;
  width: 100%;
  height: 52px;
  background-color: #f5f5f5;

  th {
    padding: 0.5rem;
  }
}

.patient-list-header > label {
  word-break: break-all;
  margin: 0 !important;
}

table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;

  tr {
    td {
      border-bottom: 2px solid #f5f5f5 !important;

      &:first-of-type {
        border-left: 2px solid #f5f5f5;
      }

      &:last-of-type {
        border-right: 2px solid #f5f5f5;
      }
    }
  }
}
</style>
